const foodRecipesJS = [
  {
    Recipe: "Drunken Noodles",
    Cuisine: "Thai",
    Id: 1,
    Image: "/images/drunken-noodles-dish.jpg",
    IngredientsImage: [
      "/images/drunken-noodles-ingredients.jpg",
    ],
    Ingredients: [
      "Rice noodles",
      "Thai basil",
      "Chicken thighs",
      "Thai chiles/seranos",
      "Green onion",
      "Ginger",
      "Garlic",
      "Fish sauce",
      "Sesame oil",
      "Soy sauce",
      "Oyster sauce",
    ],
    Description:
      "One of my favorite Thai dishes that truly slaps.",
    StepImages: [
      "/images/drunken-noodles-step-1.jpg",
      "/images/drunken-noodles-step-2.jpg",
      "/images/drunken-noodles-dish.jpg",
    ],
  },
  {
    Recipe: "Spaghetti and Meat Sauce",
    Cuisine: "Italian",
    Id: 2,
    Image: "/images/spaghetti_plate.jpg",
    IngredientsImage: ["/images/spaghetti_ingredients.jpg"],
    Ingredients: [
      "1 lb Italian Sausage",
      "Chicken stock",
      "2 cans crushed tomatoes",
      "Parmesan cheese",
      "White wine",
      "1-2 stocks celery",
      "1 yellow onion",
      "Olive oil",
      "4-5 garlic cloves",
      "Tomato paste",
      "Salt",
      "Pepper",
      "Italian Season",
      "Fresh parsley",
    ],
    Description:
      "A timeless dinner. I've gone through many recipe iterations to find my favorite version, I hope you enjoy.",
    StepImages: [
      "/images/spaghetti_prep_1.jpg",
      "/images/spaghetti_prep_1.1.jpg",
      "/images/spaghetti_prep_2.jpg",
      "/images/spaghetti_prep_3.jpg",
      "/images/spaghetti_prep_4.jpg",
      "/images/spaghetti_prep_5.jpg",
    ],
  },
  {
    Recipe: "Lasagna",
    Cuisine: "Italian",
    Id: 3,
    Image: "/images/lasagna-slice.jpg",
    IngredientsImage: ["/images/lasagna-ingredients.jpg"],
    Ingredients: [
      "Two cans Tomato sauce",
      "Ground beef",
      "Ground Italian sausage",
      "Yellow onion",
      "Garlic",
      "One egg",
      "Italian Parsley",
      "Ricotta Cheese",
      "Fresh Mozzarella",
      "Parmesan cheese",
      "Basil",
      "Lasagna noodles",
    ],
    Description: "Inject it into my veins.",
    StepImages: [
      "/images/lasagna-beef-pork-1.jpg",
      "/images/lasagna-cooking-1.jpg",
      "/images/lasagna-uncooked.jpg",
      "/images/lasagna-cooked.jpg",
    ],
  },
  {
    Recipe: "Roasted Tomatillo Salsa Version One",
    Cuisine: "Mexican",
    Id: 6,
    Image: "/images/tomatillo_salsa_v1.jpg",
    IngredientsImage: [
      "/images/tomatillo_salsa_ingredients.jpg",
    ],
    Ingredients: [
      "5-6 Tomatillos",
      "2-3 Jalapenos",
      "Quarter of an Onion",
      "4-5 Garlic cloves",
      "Cilantro",
      "Canola oil",
      "1 tsp kosher salt",
    ],
    Description:
      "Roasting all the ingredients under the broiler for a thicker salsa.",
    StepImages: ["/images/tomatillo_salsa_v1_step.jpg"],
  },
  {
    Recipe: "Roasted Tomatillo Salsa Version Two",
    Cuisine: "Mexican",
    Id: 7,
    Image: "/images/tomatillo_salsa_v2.jpg",
    IngredientsImage: [
      "/images/tomatillo_salsa_ingredients.jpg",
    ],
    Ingredients: [
      "5-6 Tomatillos",
      "2-3 Jalapenos",
      "Quarter of an Onion",
      "4-5 Garlic cloves",
      "Cilantro",
      "Canola oil",
      "1 tsp kosher salt",
    ],
    Description:
      "Roasted tomatillo salsa where you roast the tomatillos under the broiler and pan fry the jalapenos, garlic, and onion.",
    StepImages: [
      "/images/tomatillo_salsa_v2_step1.jpg",
      "/images/tomatillo_salsa_v2_step2.jpg",
      "/images/tomatillo_salsa_v2_step3.jpg",
      "/images/tomatillo_salsa_v2_step4.jpg",
    ],
  },
  {
    Recipe: "Chicken Gyro",
    Cuisine: "Mediterranean",
    Id: 5,
    Image: "/images/chicken_gyro_plate.jpg",
    IngredientsImage: [
      "/images/chicken_gyro_ingredients.jpg",
    ],
    Ingredients: [
      "Chicken thighs",
      "Mayo",
      "Plain greek yogurt",
      "Lemon",
      "Olive oil",
      "Garlic powder",
      "Ground cinnamon",
      "Ground cumin",
      "Salt",
      "Pepper",
      "Smoked paprika",
      "Onion powder",
      "Ground coriander",
      "Oregano",
      "Lettuce",
      "Tomato",
      "Cucumber",
      "Pita or gyro",
      "Basmati rice",
      "Onion",
      "Tumeric",
      "Bay leaves",
      "Ground ginger",
      "Butter",
      "Garlic",
      "Chicken Stock",
      "Salt",
      "Plain greek yogurt",
      "Mayo",
      "Olive oil",
      "Lemon",
      "Salt",
      "Garlic",
      "Oregano",
      "Dill",
    ],
    Description:
      "One of the best recipes for chicken ever.",
    StepImages: [
      "/images/chicken_gyro_step1.jpg",
      "/images/chicken_gyro_step2.jpg",
      "/images/chicken_gyro_step3.jpg",
      "/images/chicken_gyro_step4.jpg",
      "/images/chicken_gyro_step5.jpg",
      "/images/chicken_gyro_rice_step1.jpg",
      "/images/chicken_gyro_rice_step2.jpg",
      "/images/chicken_gyro_veg.jpg",
      "/images/chicken_gyro_sauce.jpg",
      "/images/chicken_gyro_plate2.jpg",
    ],
  },
  {
    Recipe: "Fried Chicken",
    Cuisine: "American",
    Id: 8,
    Image: "/images/fried_chicken_plate.jpg",
    IngredientsImage: [""],
    Ingredients: [
      "Chicken tenders",
      "Buttermilk",
      "Mustard",
      "Pickle juice",
      "1 egg",
      "Flour",
      "Chili powder",
      "Smoked paprika",
      "Garlic powder",
      "Salt",
      "Pepper",
      "Canola oil",
    ],
    Description:
      "Fried chicken tenders to rival that of your favorite chicken joint.",
    StepImages: [
      "/images/fried_chicken_step1.jpg",
      "/images/fried_chicken_step2.jpg",
      "/images/fried_chicken_step3.jpg",
      "/images/fried_chicken_step4.jpg",
    ],
  },
  {
    Recipe: "Sundubu-Jjigae",
    Cuisine: "Korean",
    Id: 9,
    Image: "/images/sundubu_step_four.jpg",
    IngredientsImage: ["/images/sundubu_ingredients.jpg"],
    Ingredients: [
      "2 tbsp gochujang paste",
      "1 cup gochugaru(Korean red chili pepper flakes)",
      "Sesame oil",
      "3 tbsp soy sauce",
      "1 ib ground pork",
      "1/2 cup scallions",
      "1 cup yellow onion",
      "7-9 garlic cloves",
      "1 & 1/2 tbsp minced ginger",
      "1 tbsp ground pepper",
      "3 tbsp salt",
      "2 tbsp sugar",
      "Silken tofu",
      "Chicken stock",
      "Eggs",
      "Jasmine rice",
      "Coconut oil, or coconut milk",
    ],
    Description:
      "A classic spicy Korean stew served over rice, perfect dish for a cold day.",
    StepImages: [
      "/images/sundubu_step_one.jpg",
      "/images/sundubu_step_two.jpg",
      "/images/sundubu_step_three.jpg",
      "/images/sundubu_step_four.jpg",
      "/images/sundubu_plate.jpg",
    ],
  },
  {
    Recipe: "Tonkatsu",
    Cuisine: "Japanese",
    Id: 10,
    Image: "/images/tonkatsu_plate2.jpg",
    IngredientsImage: ["/images/tonkatsu_ingredients.jpg"],
    Ingredients: [
      "Pork Chops",
      "Eggs",
      "All purpose flour",
      "Garlic Powder",
      "Ground Ginger",
      "Canola oil",
      "Salt",
      "Pepper",
      "Panko bread crumbs",
      "Soy sauce",
      "Oyster sauce",
      "Sesame oil",
      "Ketchup",
      "Worcestershire sauce",
      "Sugar",
    ],
    Description:
      "A delicious Japanese dish, and one of my favorite ways to do pork chops.",
    StepImages: [
      "/images/tonkatsu_step_one.jpg",
      "/images/tonkatsu_step_three.jpg",
      "/images/tonkatsu_step_four.jpg",
      "/images/tonkatsu_curry_plate.jpg",
    ],
  },
  {
    Recipe: "Chicken Stock",
    Cuisine: "Miscellaneous",
    Id: 11,
    Image: "/images/chicken_broth_main.jpg",
    IngredientsImage: [
      "/images/chicken-stock-ingredients.jpg",
      // "/images/chicken_broth_ingredients_2.jpg"
    ],
    Ingredients: [
      "Onion",
      "Carrot",
      "Celery",
      "Garlic",
      "Ginger",
      "Fresh thyme",
      "Chicken carcass",
      "Peppercorns",
      "Bay leaves",
    ],
    Description:
      "Home-made is always better than store-bought.",
    StepImages: [
      "/images/chicken-stock1.jpg",
      "/images/chicken-stock2.jpg",
      "/images/chicken-stock3.jpg",
      "/images/chicken-stock4.jpg",
      "/images/chicken-stock5.jpg",
      "/images/chicken_broth_step3.jpg",
    ],
  },
  {
    Recipe: "Japanese Curry",
    Cuisine: "Japanese",
    Id: 12,
    Image: "/images/japanese_curry_plate.jpg",
    IngredientsImage: [
      "/images/japanese_curry_ingredients.jpg",
    ],
    Ingredients: [
      "Japanese curry mix",
      "Chicken stock",
      "Garlic",
      "Yellow onion",
      "Russet potatoes",
      "Carrots",
    ],
    Description:
      "A warm and hearty Japanese curry that pairs perfectly with Tonkatsu.",
    StepImages: [
      "/images/japanese_curry_step_2.jpg",
      "/images/japanese_curry_step_3.jpg",
      "/images/japanese_curry_step_4.jpg",
      "/images/japanese_curry_step_5.jpg",
    ],
  },
  {
    Recipe: "Chicken and Sausage Gumbo",
    Cuisine: "Cajun",
    Id: 13,
    Image: "/images/gumbo_plate.jpg",
    IngredientsImage: ["/images/gumbo_ingredients.jpg"],
    Ingredients: [
      "Andouille sausage",
      "Chicken thighs",
      "Chicken stock",
      "Garlic",
      "Yellow onion",
      "Green bell pepper",
      "Celery",
      "Jalapeno",
      "Amber beer",
      "All purpose flour",
      "Grape seed oil",
      "Fresh thyme",
      "Bay leaves",
      "Salt",
      "Pepper",
    ],
    Description:
      "An essential cold weather meal to add to your cooking repertoire.",
    StepImages: [
      "/images/gumbo_step1.jpg",
      "/images/gumbo_step2.jpg",
      "/images/gumbo_step3.jpg",
      "/images/gumbo_step4.jpg",
      "/images/gumbo_step5.jpg",
      "/images/gumbo_step6.jpg",
      "/images/gumbo_step7.jpg",
    ],
  },
  {
    Recipe: "Spicy Mexican Chicken",
    Cuisine: "Mexican",
    Id: 14,
    Image: "/images/spicy_mexican_main.jpg",
    IngredientsImage: [
      "/images/spicy_mex_chicken_ingredients.jpg",
    ],
    Ingredients: [
      "10-12 chile de arbol",
      "2-3 chile de ristra",
      "2 ibs chicken thighs",
      "1 Half yellow Onion, or 1 whole small yellow onion",
      "4-5 Garlic cloves",
      "1 tbsp salt",
      "1 tbsp pepper",
      "1 tsp ground cumin",
      "1 tsp chili powder",
      "Juice of 1 naval orange",
      "2 tbsp chicken bullion",
      "2 tbsp oregano",
      "1 tbsp avocado oil"
    ],
    Description:
      "A nice mixture of sweet and savory with a little bite.",
    StepImages: [
      "/images/spicy_mexican_step1.jpg",
      "/images/spicy_mexican_step2.jpg",
      "/images/spicy_mexican_step3.jpg",
      "/images/spicy_mexican_step4.jpg",
    ],
  },
  {
    Recipe: "Jalapeno Salsa",
    Cuisine: "Mexican",
    Id: 15,
    Image: "/images/jalapeno_salsa_main.jpg",
    IngredientsImage: [
      "/images/jalapeno_salsa_ingredients.jpg",
    ],
    Ingredients: [
      "6-7 jalapenos",
      "Quarter of an Onion",
      "4-5 Garlic cloves",
      "1/2 cup cilantro",
      "1/3 cup canola oil",
      "1 tsp kosher salt",
    ],
    Description:
      "One of my all time favorite salsas and the springboard for the roasted tomatillo salsas.",
    StepImages: [
      "/images/jalapeno_salsa_step1.jpg",
      "/images/jalapeno_salsa_step2.jpg",
    ],
  },
  {
    Recipe: "Carne Asada",
    Cuisine: "Mexican",
    Id: 16,
    Image: "/images/carne_asada_main.jpg",
    IngredientsImage: [
      "/images/carne_asada_ingredients.jpg",
    ],
    Ingredients: [
      "Flank Steak",
      "1 orange",
      "1 lime",
      "4-5 minced garlic cloves",
      "1/2 cup of chopped cilantro",
      "1/3 cup Olive oil",
      "1/4 cup soy sauce",
      "2 tbsp balsamic vinaigrette",
      "2 tbsp oregano",
      "1 tbsp onion powder",
      "1 tsp cayenne",
      "1 tsp ground cumin",
      "1 tsp chipotle powder",
      "1 tsp chili powder",
    ],
    Description:
      "A delicious carne asada recipe with a couple unique ingredients.",
    StepImages: [
      "/images/carne_asada_step1.jpg",
      "/images/carne_asada_step2.jpg",
      "/images/carne_asada_step3.jpg",
    ],
  },
];

export default foodRecipesJS;
